html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #F5F5F5 ;
  font-family: 'Fanwood Text', serif;
  font-weight: 400;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'League Spartan', sans-serif;
  font-weight: 900;
  line-height: 72px;
  margin: 0;
}

p {
  font-size: 24px;
  line-height: 32px;
  text-align: justify;
}

a {
  color: #8fda00;
}

.App {
  display: flex;
}
